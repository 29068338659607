import Image from "@atoms/Image";
import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import router from 'next/router';

const ProductCard = ({ details }) => {
    const { cta, image, header, yieldValue } = details;
    return (<div onClick={() => router.push(cta)} className="cursor-pointer overflow-hidden asset-class-content-wrapper relative bg-basicWhite rounded-xl py-4 px-3 md:p-6 flex flex-col justify-between"
    >
        <div>
            <Image src={image} className="w-10 md:w-14 h-10 md:h-14" />
            <Text content={header} className="mt-[6px] md:mt-2 text-gray-900 p4-semibold md:p1-semibold" htmlTag="h3" />
        </div>
        <div>
            <Text content={texts?.Yield} className="mt-4 text-gray-500 p5-regular md:p3-medium" />
            <Text content={yieldValue} className="mt-0.5 text-gray-900 p4-medium md:p2-semibold" />
        </div>
        <Image src="/images/homepage/arrow-forward.svg" alt="arrow-img" className="hidden md:block arrow-icon absolute top-0 right-0" />
    </div>);
};

export default ProductCard;