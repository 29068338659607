import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import ExploreOpportunitiesButton from "../ExploreOpportunitiesButton";
import { ProductsData } from "@helpers/homepageData";
import Image from "@atoms/Image";
import ProductCard from "./ProductCard";

const Products = ({ onButtonClick }) => {
    return (
        <div className="relative max-width-wrapper flex flex-col md:flex-row gap-y-4 items-center md:justify-between py-12 px-4 md:py-16 xl:py-18 md:px-6 xl:px-[250px]">
            <div className="grow-1 shrink-0 md:mr-10 xl:mr-16">
                <Text
                    content={texts?.FixedIncomeDestination}
                    className="text-center md:text-start h4-semibold md:h3-semibold text-primary-500 md:whitespace-pre-line"
                    htmlTag="h2"
                />
                <ExploreOpportunitiesButton
                    className="hidden mt-6 md:block"
                    onButtonClick={onButtonClick}
                    gtmTag="gtm-click-explore-opportunities-btn-in-homepage"
                />
            </div>
            <div className="grid grid-cols-2 gap-4 xl:gap-10 grow-0 shrink-1">
                {ProductsData?.map((item, index) => (
                    <ProductCard key={index} details={item} />
                ))}
            </div>
            <ExploreOpportunitiesButton
                className="mt-6 md:hidden"
                onButtonClick={onButtonClick}
                gtmTag="gtm-click-explore-opportunities-btn-in-homepage"
            />
            <div className="absolute -top-1 md:top-10 xl:top-[84px] -left-4 md:-left-10 xl:left-28">
                <Image
                    src="/images/background/orange-skeleton.svg"
                    className="md:scale-y-[-1] xl:!scale-y-100 w-[92px] md:w-[155px] xl:w-[207px] h-[66px] md:h-[111px] xl:h-[148px]"
                />
            </div>
        </div>
    );
};

export default Products;